@import "minima";

$primary-text-color: #333333;
$medium-text-color: #828282;
$soft-text-color: #bdbdbd;
$very-soft-gray: #e8e8e8;

@mixin serif-font {
  font: 600 18px/1.5 Iowan Old Style, Apple Garamond, Baskerville,
    Times New Roman, Droid Serif, Times, Source Serif Pro, serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, serif;
  @content;
}

@mixin sans-serif-font {
  font: 400 14px/1.5 avenir next, avenir, -apple-system, BlinkMacSystemFont,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  @content;
}

body {
  @include sans-serif-font;
  letter-spacing: 0.5px;
  line-height: 1.8em;
  color: $primary-text-color;
  position: relative;
  max-width: 960px;
  margin: auto;
  padding: 0 1.5rem;
}

h1,
h2,
h3,
h4,
h5 {
  @include serif-font;
}

h2,
h3 {
  margin-top: 1em;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -ms-transition: opacity 0.1s ease-in-out;
  -o-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
  &:visited {
    color: inherit;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.5;
  }
}

.page-content {
  padding: 1rem 0;
}

article a {
  text-decoration: underline;
}

/* Styling for the header layout */

.site-header {
  border-top: 0;
  border-bottom: 1.5px solid $very-soft-gray;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0 0;
  & li {
    list-style: none;
    padding: 0.25rem 0.5rem 0rem 0.5rem;
    letter-spacing: 1px;
  }
}

.active {
  font-weight: 600;
  text-decoration: underline;
}

/* Styling for main page */

.grid-container {
  list-style: none;
  margin-top: -10px;
  display: grid;
  grid-gap: 2.3rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  & a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & .post-list-heading {
      text-decoration: none;
      letter-spacing: 1.5px;
      padding: 1.5rem 1rem;
      text-align: center;
      @include sans-serif-font;
    }
  }
}

.post-meta {
  font-size: 10px;
  color: $soft-text-color;
}

/* Styling for the footer */

.site-footer {
  padding: 1rem 0 2.5rem 0;
  border-top: 1.5px solid $very-soft-gray;
  color: $medium-text-color;
}

.u-url {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  & li {
    padding-top: 1em;
    flex: 1 1 0px;
    min-width: 300px;
  }
}

.site-description {
  text-align: center;
  @media screen and (max-width: 600px) {
    text-align: left;
  }
}

.social-medias {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 925px) {
    justify-content: flex-start;
  }
  & a {
    margin-right: 10px;
  }
}

/* Styling for the mona lisa picture on the about page */

@media (max-width: 640px) {
  #mona_lisa {
    display: none;
  }
}
